<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="visibleFields.length !== 0"
        class="position-relative assistanceTableView"
        primary-key="number"
        show-empty
        responsive
        striped
        hover
        empty-text="Não há assistências cadastradas."
        :items="assistances"
        :fields="visibleFields"
        :busy="isLoading"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        tbody-tr-class="assistanceTableCustomHeight"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando ...</strong>
          </div>
        </template>

        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'order-detail', params: { id: field.item.order } }"
              class="font-weight-bold d-block text-nowrap text-right"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(order__contract__bidding__client__name)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.order_set.contract_set.bidding_set.client_set.name }}
          </span>
        </template>

        <template #cell(product__name)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.product_set.name }}
          </span>
        </template>

        <template #cell(type__name)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.type_set.name }}
          </span>
        </template>

        <template #cell(technician_name)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.technician_name }}
          </span>
        </template>

        <template #cell(date_scheduled)="field">
          <span
            v-if="field.item.date_scheduled"
            class="text-nowrap"
          >
            {{ moment(field.item.date_scheduled).format('DD/MM/YYYY') || '' }}
          </span>
        </template>

        <template #cell(status__name)="field">
          <div
            class="d-block text-nowrap font-weight-bold"
          >
            <b-badge
              pill
              class="badgeStyle text-capitalize sizeMin d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.status_set.color}"
            >
              {{ field.item.status_set.name }}
            </b-badge>
          </div>
        </template>

      </b-table>
      <div
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  VBTooltip,
  BBadge,
  BSpinner,
  BMedia,
  BLink,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import moment from 'moment'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BSpinner,
    BMedia,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1
    const perPage = 10

    const sortOptions = {
      sortBy: 'id',
      isSortDirDesc: false,
    }

    const lastSearch = ''

    const assistances = []

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'order__contract__bidding__client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'product__name',
        label: 'Produto',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'type__name',
        label: 'Tipo de Serviço',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'technician_name',
        label: 'Técnico',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'date_scheduled',
        label: 'Data programada',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap assistanceTableColumn',
      },
    ]

    const fetchId = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('assistances')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const meta = new MetaData('AssistanceTable')

    return {
      fetchId,
      totalItems,
      perPage,
      currentPage,
      lastSearch,
      syncLoad,
      assistances,
      tableColumns,
      isLoading,
      sortOptions,
      meta,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }
        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, index) => {
          if (obj.key === element.key) {
            this.tableColumns[index].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const assistanceFilter = this.filter
      assistanceFilter.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        assistanceFilter.ordering = `-${this.sortOptions.sortBy}`
      } else {
        assistanceFilter.ordering = this.sortOptions.sortBy
      }

      syslic
        .order
        .assistance
        .fetchAssistanceList(assistanceFilter)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.assistances = response.data.results
            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('assistances')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados das assistências',
                text: 'Não foi possível ler os dados das assistências do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: 'false',
              },
            })
            this.syncLoad.ready('assistances')
          }
        })
    },
    onRowSelected(item) {
      router.push({
        name: 'order-edit',
        params: {
          id: item.order,
          tab: 6,
          assistance: item.id,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
    }
  },
}
</script>

<style lang="scss">
.assistanceTableView {
  border-radius: .4rem;
}

.assistanceTableColumn {
  min-width: 6rem;
}

.assistanceTableCustomHeight {
  height: 4.5rem;
}

.badgeStyle {
  height: 2.2rem;
  min-width: 10rem;
  font-size: 1rem;
}
</style>
